import Cookies from 'universal-cookie';

export function setCookies(name: string, value: string) {
    // new Cookies().set(name, value, { path: '/', secure: false, sameSite: 'lax', domain: 'localhost' });
    new Cookies().set(name, value, { path: '/', secure: true, sameSite: 'none', domain: process.env.REACT_APP_DOMAIN_COOKIE });
}

export function getCookies(name: string) {
    return new Cookies().get(name);
}

export function removeCookies(name: string) {
    // new Cookies().remove(name, { path: '/', secure: false, sameSite: 'lax', domain: 'localhost' });
    new Cookies().remove(name, { path: '/', secure: true, sameSite: 'none', domain: process.env.REACT_APP_DOMAIN_COOKIE });
}